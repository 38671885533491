
import { Component, Prop, Mixins } from "vue-property-decorator";
import { formatDate } from "@/utils/formatters";
import { bus } from "@/main";
import { HasTimelineEntry } from "@/mixins/has-timeline-entry";

@Component
export default class TimelineEntryCard extends Mixins(HasTimelineEntry) {
    @Prop({ default: false }) hideLine?: boolean;
    @Prop({ default: false }) isLast?: boolean;
    @Prop({ default: false }) canEdit!: boolean;

    bus = bus;
}
