import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TimelineEntryType } from "@/store/modules/timeline.store";
import { formatCurrency, formatTimeAgo, formatDate } from "@/utils/formatters";

@Component
export class HasTimelineEntry extends Vue {
  @Prop() timelineItem!: TimelineItem;

  formatCurrency = formatCurrency;

  formatTimeAgo = formatTimeAgo;

  formatDate = formatDate;

  icons: { [key in TimelineEntryType]: string } = {
    MESSAGE: "icon-chat-alt",
    ACTIVITY_CREATED: "icon-chat-alt",
    GENERAL: "icon-exclamation",
    BID_UPDATED: "icon-hand",
    BID_REJECTED: "icon-hand",
    ACTIVITY_LOST: "icon-hand",
    BID_ACCEPTED: "icon-badge-check",
    DOCUMENT_AVAILABLE: "icon-download",
    SIGNATURE_REQUEST: "icon-pencil",
    TASK_COMPLETED: "icon-clipboard-check",
    SMALL_MILESTONE: "icon-check-circle",
    LARGE_MILESTONE: "icon-flag",
    ACTIVITY_WORK_STATUS_UPDATED: "icon-refresh",
    NOTE: "icon-pencil-alt",
    PARTNER_NOTE: "icon-pencil-alt",
    SELLER_NOTE: "icon-pencil-alt",
    EMAIL: "icon-mail",
    PHONE: "icon-phone",
    MANDATE_CREATED: "icon-badge-check",
    MANDATE_UPDATED: "icon-refresh",
    MANDATE_SIGNED: "icon-pencil",
    SALES_PROMISE_EMPTY: "icon-exclamation",
    APPOINTMENT_CREATED: "icon-calendar",
    REVIEW_PLACED: "icon-star",
  };

  get entryCreator() {
    let name = "";
    if (this.timelineItem.initiator?.first_name) {
      name += this.timelineItem.initiator.first_name;
    }
    if (this.timelineItem.initiator?.last_name) {
      name += ` ${this.timelineItem.initiator.last_name}`;
    }

    if (name === "") {
      return `<span class="text-black-300 font-normal">Hubr</span>`;
    }

    return `<span class="text-accent font-medium">${name}</span>`;
  }

  get titleParams() {
    let params: { [key: string]: string } = {
      initiator: this.entryCreator,
    };

    if ([TimelineEntryType.general].includes(this.timelineItem.type)) {
      params.prefix = this.timelineItem.body.prefix ?? "";
      params.subject = this.timelineItem.body.subject ?? "";
      params.subject_2 = this.timelineItem.body.subject_2 ?? "";
      params.action = this.timelineItem.body.action ?? "";
    }

    if ([TimelineEntryType.activityLost].includes(this.timelineItem.type) && this.timelineItem.body.activity) {
      params.lost_reason = this.timelineItem.body.activity.lost_reason;
    }

    if ([TimelineEntryType.bidUpdated, TimelineEntryType.bidAccepted].includes(this.timelineItem.type) && this.timelineItem.body.bid) {
      params.currency = this.formatCurrency(this.timelineItem.body.bid.amount);
    }

    if ([TimelineEntryType.activityWorkStatusUpdated].includes(this.timelineItem.type) && this.timelineItem.body.from && this.timelineItem.body.to) {
      params.work_status_from = `<span class="text-accent font-medium">${this.$t(`property.work_status.${this.timelineItem.body.from}`)}</span>`;
      params.work_status_to = `<span class="text-accent font-medium">${this.$t(`property.work_status.${this.timelineItem.body.to}`)}</span>`;
    }

    if ([TimelineEntryType.taskCompleted].includes(this.timelineItem.type) && this.timelineItem.body.task) {
      params.task_title = `<span class="text-accent font-medium">${this.timelineItem.body.task.title}</span>`;
    }

    if ([TimelineEntryType.reviewPlaced].includes(this.timelineItem.type) && this.timelineItem.body.review) {
      params.seller = `<span class="text-accent font-medium">${this.timelineItem.body.review.reviewer.first_name} ${this.timelineItem.body.review.reviewer.last_name}</span>`;
    }

    if ([TimelineEntryType.appointmentCreated].includes(this.timelineItem.type) && this.timelineItem.body.appointment) {
      params.realtor = `<span class="text-accent font-medium">${this.timelineItem.body.appointment.invited_by}</span>`;
      if (this.timelineItem.body.appointment?.planned_at != null) {
        const formattedDate = formatDate(this.timelineItem.body.appointment.planned_at, "dddd D MMMM YYYY");
        const formattedTime = formatDate(this.timelineItem.body.appointment.planned_at, "HH:mm");
        params.date = `<span class="text-accent font-medium">${formattedDate}</span> om <span class="text-accent font-medium">${formattedTime}</span>`;
      }
    }

    return params;
  }

  get title() {
    let titleType: string = this.timelineItem.type;

    if ([TimelineEntryType.appointmentCreated].includes(this.timelineItem.type) && this.timelineItem.body.appointment && this.timelineItem.body.appointment.action) {
      titleType = "";
    }

    if (!this.$te(`views.timeline.${titleType}_title`)) {
      return false;
    }

    return this.$t(`views.timeline.${titleType}_title`, this.titleParams);
  }

  get description() {
    let descriptionType: string = this.timelineItem.type;

    if ([TimelineEntryType.smallMilestone, TimelineEntryType.largeMilestone].includes(this.timelineItem.type)) {
      return this.timelineItem.body.title;
    }

    if ([TimelineEntryType.appointmentCreated].includes(this.timelineItem.type) && this.timelineItem.body.appointment && this.timelineItem.body.appointment.action) {
      if (this.timelineItem.body.appointment.action === "Bezoek") {
        descriptionType = "APPOINTMENT_VISIT_CREATED";
      }
      if (this.timelineItem.body.appointment.action === "Compromis") {
        descriptionType = "APPOINTMENT_COMPROMIS_CREATED";
      }
    }

    if (!this.$te(`views.timeline.${descriptionType}_description`)) {
      return false;
    }

    return this.$t(`views.timeline.${descriptionType}_description`, this.titleParams);
  }
}
